// Show Input to enter password, email
// Show Error if password does not match
// Show Welcome Message once is signup sucessfully

import { useAuth0 } from '@auth0/auth0-react';
import { useState } from "react";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKDatePicker from "components/MKDatePicker";
import MKAlert from "components/MKAlert";
import MKInput from "components/MKInput";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// ICONS:
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import StartIcon from '@mui/icons-material/Start';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import AlarmIcon from '@mui/icons-material/Alarm';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';

const { getEmail, getName, checkCredentials, setAuth, getAuth, handleAuthentication,  getAuthError, getAuthLogin } = require('../../../../libs/authentication');

const {getWorkTime ,startWork, stopWork, getStatus } = require('../../../../libs/tiempolaboral');


export const SignIn= (props) => {

	//console.log("SignIn ", props);


	let [email, setEmail] = useState(null);
	let [password, setPassword] = useState(null);
	let [working, setWorking] = useState(null);
	let [name, setName] = useState(null);
	let [timeStartHour, setTimeStartHour] = useState(null);
	let [timeStartMin, setTimeStartMin] = useState(null);
	let [enlapsedHour, setEnlapsedHour] = useState(null);
	let [enlapsedMin,  setEnlapsedMin] = useState(null);
	let [registerAction, setRegisterAction] = useState(false);
	let [start, setStart] = useState(false);
	let [notAllowed, setNotAllowed] = useState(false);
	let [stop, setStop] = useState(false);
	let [accessDB, setAccessDB] = useState(false);
	let [viewTime, setViewTime] = useState(false);
	let [welcome, setWelcome] = useState(true);
	let [start1,  setStart1] = useState(null);
	let [start2,  setStart2] = useState(null);
	let [end1,  setEnd1] = useState(null);
	let [end2,  setEnd2] = useState(null);
	let [dayoff1,  setDayoff1] = useState(null);
	let [dayoff2,  setDayoff2] = useState(null);


	const reset = async () => {
				setStop(false);
				setStart(false);
				setAccessDB(false);
				setRegisterAction(false);
				setViewTime(false);
				setWelcome(false);
				setNotAllowed(false);
	}

	const backHome = async () => {
				reset();
				setWelcome(true);
        console.log('backHome ...' );
	}

	const doneStart = async (allowed) => {
 				reset();
				if (allowed==true){
					setStart(true);
				}else{
					setNotAllowed(true);
					console.log("Employeed not in the working time")
				}
        console.log('doneStart  ...' );
	}

	const handleStart = async () => {
			  reset();
				setAccessDB(true);
        console.log('handleStart ...', email);
        startWork(doneStart,email);
	}

	const doneStop = async () => {
				setStop(true);
				setAccessDB(false);
				setRegisterAction(false);
        console.log('doneStop  ...' );
	}

	const handleStop = async () => {
				setStop(false);
				setAccessDB(true);
				setRegisterAction(false);
        console.log('handleStop ...',  email );
        stopWork(doneStop,email);
	}

	const doneRegister = async (lastaction,lasttime) => {
        console.log('doneRegister ...', lastaction, lasttime);
        setRegisterAction(true);

        if (lastaction=='start'){

		        const dateStart=new Date(lasttime)
				//		console.log("start:", dateStart.toTimeString({timeZone: "Europe/Madrid"}));
						setTimeStartHour(dateStart.getHours())
						setTimeStartMin(dateStart.getMinutes())
						const dateEnd=new Date();
				//		console.log("end:", dateEnd.toTimeString({timeZone: "Europe/Madrid"}) );
						const diff= dateEnd-dateStart;
						const min=diff/1000/60
						const diff_hour=Math.floor(min/60)
						const diff_min=Math.floor(min%60)
				//		console.log("diff hour:min", diff_hour, " : ", diff_min);
						setEnlapsedHour(diff_hour);
						setEnlapsedMin(diff_min);
						setWorking(true);

        }else{

							setWorking(false);
							setEnlapsedHour(0);
							setEnlapsedMin(0);
        }
	}

	
	const handleRegister = async () => {
				const email0=getEmail();
				setEmail(email0);
				reset();
				setRegisterAction(true);
        console.log('handleRegister ...', email0 );
        getStatus(doneRegister,email0);
	}

	const doneView = async (start1,end1,start2,end2, dayoff1, dayoff2) => {
			  	reset();
			  	setViewTime(true);
     //   console.log('doneView ...', email, start1, end1, start2, end2, dayoff1, dayoff2 );
				setStart1(start1);
				setStart2(start2);
				setEnd1(end1);
				setEnd2(end2);
				setDayoff1(dayoff1);
				setDayoff2(dayoff2);
	}
	
	const handleView = async () => {
			  	reset();
			  	const email0=getEmail();
				setEmail(email0);
			  	setViewTime(true);
        		console.log('handleView ...', email0 );
        		getWorkTime(doneView,email0)
	}

	const doneSignIn = async (passed,email,name) => {
				setName(name);
				setEmail(email);
				reset();
				setWelcome(true);
		        console.log('doneSignIn ...', name, email );
		        if (!passed){
		        	props.setDone('error');
		        }else{
		        	props.setDone('valid');
		        }
	}
	
	const handleSignIn = async () => {
       // console.log('handleSignIn ...' );
			  setEmail(email);
        checkCredentials(email, password, doneSignIn);
	}

/////////////////////////////////////////////////////////////////////////////////////

	return (
		<>
		{(getAuth()==false)&& <>
		  {(getAuthError())&&
              <MKAlert justifyContent="center"  color="error" > <SentimentDissatisfiedIcon/>&nbsp; Lo sentimos, email o password incorrectos </MKAlert>
      	  }

					  <Card
					        sx={{
					          p: 2,
					          mx: { xs: 2, lg: 1 },
					          mt: 2,
					          mb: 4,
					          boxShadow: ({ boxShadows: { xxl } }) => xxl,
					        }}
					      >

					     <MKBox component="section" pt={3} pb={8}>
					         <Container>
					         
					            <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
					            <Grid item xs={12} md={3} lg={3}>
					                 
					               <MKTypography variant="h5" my={4} >
					                 Sign In
					               </MKTypography>

					               	<MKInput sx={{mt: 1 }} type="text" label="Email" autocomplete="on" onChange={(e)=> setEmail(e.target.value)} />
					                <MKInput type="password" sx={{mt: 2 }} type="text" autocomplete="on" label="Password" onChange={(e)=> setPassword(e.target.value)} />
					            	 <MKButton sx={{mt: 2 }} variant="gradient" color="info" onClick={handleSignIn}>
                    					<LoginIcon variant="filled"  fontSize="medium" />&nbsp;
                    							Entrar
           							 </MKButton> 

					          </Grid>
					          </Grid>
					        
					        </Container>
					        </MKBox>
					      </Card>
			</>}

			{(getAuth()==true && accessDB==true)&& <>
				<Grid container justifyContent="center" sx={{ textAlign: "center" }}>
          <MKAlert justifyContent="center"  color="light" > <HourglassTopIcon/>&nbsp; Por favor, espere a que se registren sus acciones ...  </MKAlert>
        	}
				</Grid>
			</>}

			{(getAuth()==true && welcome==true ) && <>

			 		<Grid container justifyContent="center" sx={{ textAlign: "center" }}>
              <Grid item xs={12} md={3} lg={3}>
                  <MKTypography
                variant="h2"
                my={4}
                >
                   Bienvenido a tu tiempo laboral

               </MKTypography>
                <MKTypography
                variant="h4"
                my={4}
                >
                    {getName()}

               </MKTypography>
            </Grid>
          </Grid>

          <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
           	<Grid item xs={12} md={3} lg={3}>
          			<MKButton sx={{mt: 2 }} variant="gradient" color="success" onClick={handleRegister}>
                    					<AlarmIcon variant="filled"  fontSize="large" />&nbsp;
                    							Empezar/Terminar
           	  	</MKButton> 
           	 </Grid>

           	 <Grid item xs={12} md={3} lg={3}>
           	  	<MKButton sx={{mt: 2 }} variant="gradient" color="info" onClick={handleView}>
                    					<ViewTimelineIcon  fontSize="large" />&nbsp;
                    							Ver horario
           	  	</MKButton> 
           	  </Grid>
          </Grid>

		    </>}

		    {(getAuth()==true && registerAction==true && working==true) && <>
		    	<Grid container justifyContent="center" sx={{ textAlign: "center" }}>
		    			 <Grid item xs={12} md={3} lg={3}>
                <MKTypography
                variant="h4"
                my={4}
                >
                   Trabajando 

               </MKTypography>
               <MKTypography
                variant="h4"
                my={4}
                >
                   Empezo a la hora {timeStartHour}:{timeStartMin}

               </MKTypography>
                <MKTypography
                variant="h4"
                my={4}
                >
                   Tiempo transcurrido {enlapsedHour}:{enlapsedMin}

               </MKTypography>
              </Grid>

              <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
                <Grid item xs={12} md={3} lg={3}>
           	  	<MKButton sx={{mt: 2 }} variant="gradient" color="info" onClick={backHome}>
                    <ArrowBackIcon/>&nbsp;
                    Volver
                </MKButton>
                </Grid>
                 <Grid item xs={12} md={3} lg={3}>
           	  		<MKButton sx={{mt: 2 }} variant="gradient" color="error" onClick={handleStop}>
                    					<StopCircleIcon fontSize="large" />&nbsp;Terminar
                    							
           	  		</MKButton> 
           	  	</Grid>
           	  </Grid>
          </Grid>

		    </>}

		     {(getAuth()==true && registerAction==true && working==false) && <>
		    	<Grid container justifyContent="center" sx={{ textAlign: "center" }}>
		    		   <Grid item xs={12} md={3} lg={3}>
                <MKTypography
                variant="h4"
                my={4}
                >
                   No trabajando 

               </MKTypography>
               </Grid>

               <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
                 <Grid item xs={12} md={3} lg={3}>
           	  	<MKButton sx={{mt: 2 }} variant="gradient" color="info" onClick={backHome}>
                    <ArrowBackIcon/>&nbsp;
                    Volver
                </MKButton>
                </Grid>

               	<Grid item xs={12} md={3} lg={3}>
           	  		<MKButton sx={{mt: 2 }} variant="gradient" color="success" onClick={handleStart}>
                    					<StopCircleIcon  fontSize="large" />&nbsp;
                    							Empezar 
           	  		</MKButton> 
           	  	</Grid>
           	   </Grid>

          </Grid>

		    </>}

		    {(getAuth()==true && registerAction==false && start==true) && <>
		    	<Grid container justifyContent="center" sx={{ textAlign: "center" }}>
		    		   <Grid item xs={12} md={3} lg={3}>
                <MKTypography
                variant="h4"
                my={4}
                >
                  Comenzado su tiempo laboral ahora

               </MKTypography>
               </Grid>

               <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
           	  	<Grid item xs={12} md={3} lg={3}>
           	  	<MKButton variant="gradient" color="info" onClick={backHome}>
                    <ArrowBackIcon/>&nbsp;
                    Volver
                </MKButton>
                </Grid>
           	   </Grid>
          </Grid>
		    </>}

		     {(getAuth()==true && registerAction==false && stop==true) && <>
		    	<Grid container justifyContent="center" sx={{ textAlign: "center" }}>
		    		   <Grid item xs={12} md={3} lg={3}>
                <MKTypography
                variant="h4"
                my={4}
                >
                  Terminado su tiempo laboral ahora

               </MKTypography>
               </Grid>

               <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
           	  	<Grid item xs={12} md={3} lg={3}>
           	  	<MKButton variant="gradient" color="info" onClick={backHome}>
                    <ArrowBackIcon/>&nbsp;
                    Volver
                </MKButton>
                </Grid>
           	   </Grid>
          </Grid>
		    </>}

		    {(getAuth()==true && registerAction==false && viewTime==true) && <>
		    	<Grid container justifyContent="center" sx={{ textAlign: "center" }}>
		    		   <Grid item xs={12} md={3} lg={3}>
                <MKTypography
                variant="h4"
                my={4}
                >
                  Su horario: 

               </MKTypography>
               </Grid>

               <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
               <Grid item xs={12} md={3} lg={3}>
                <MKTypography
                variant="h4"
                my={4}
                >
                  {start1} -> {end1} 

               </MKTypography>
               </Grid>
							</Grid>

							{(start2!=null )&& <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
               <Grid item xs={12} md={3} lg={3}>
                <MKTypography
                variant="h4"
                my={4}
                >
                  {start2} -> {end2} 

               </MKTypography>
               </Grid>
							</Grid>
						 }

               <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
           	  	<Grid item xs={12} md={3} lg={3}>
           	  	<MKButton variant="gradient" color="info" onClick={backHome}>
                    <ArrowBackIcon/>&nbsp;
                    Volver
                </MKButton>
                </Grid>
           	   </Grid>
          </Grid>
		    </>}

		     {(getAuth()==true && registerAction==false && notAllowed==true) && <>
		    	<Grid container justifyContent="center" sx={{ textAlign: "center" }}>
		    		   <Grid item xs={12} md={3} lg={3}>
                <MKTypography
                variant="h4"
                my={4}
                >
                   Aun no esta en su horario laboral, espere su turno

               </MKTypography>
               </Grid>

               <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
           	  	<Grid item xs={12} md={3} lg={3}>
           	  	<MKButton variant="gradient" color="info" onClick={backHome}>
                    <ArrowBackIcon/>&nbsp;
                    Volver
                </MKButton>
                </Grid>
           	   </Grid>
          </Grid>
		    </>}


			</>	
		)
}
