// Basic Functions TiempoLaboral called by the WebSite 

// AWS: https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/dynamodb-example-document-client.html 

var AWS = require('aws-sdk');

//console.log(process.env.REACT_APP_AWS_REGION);

var configuration={
    region: process.env.REACT_APP_AWS_REGION,
    secretAccessKey: process.env.REACT_APP_AWS_KEY,
    accessKeyId: process.env.REACT_APP_AWS_SECRET
};

AWS.config.update(configuration);

const docClient = new AWS.DynamoDB.DocumentClient();

import employees from '../employees.json';

// **********************************************
// DATABASE 
// **********************************************

const getTimes = (email) => {
    let start1=null, end1=null, start2=null, end2=null, dayoff1=null, dayoff2=null;

    employees.forEach(function(employee) {

        if (employee.email==email){
            //console.log(employee.times.length,employee.times );
            if (employee.times.length==2){
                start1=Number(employee.times[0]);
                end1=Number(employee.times[1]);
            }
            if (employee.times.length==4){
                start1=Number(employee.times[0]);
                end1=Number(employee.times[1]);
                start2=Number(employee.times[2]);
                end2=Number(employee.times[3]);
            }

          //  console.log(employee.dayoff.length, employee.dayoff );
            if (employee.dayoff.length==1){
                dayoff1=Number(employee.dayoff[0]);
            }
            if (employee.dayoff.length==2){
                dayoff1=Number(employee.dayoff[0]);
                dayoff2=Number(employee.dayoff[1]);
            }
        }
    })

    return [start1,end1,start2,end2, dayoff1, dayoff2]
}


export const getWorkTime = (setDone,  email) => {
    let start1=null, end1=null, start2=null, end2=null, dayoff1=null, dayoff2=null;

    [start1,end1,start2,end2, dayoff1, dayoff2]=getTimes(email);
    setDone(start1,end1,start2,end2, dayoff1, dayoff2)
}


const checkTimes = (email) => {

    let start1=null, end1=null, start2=null, end2=null, dayoff1=null, dayoff2=null;
    [start1,end1,start2,end2,dayoff1,dayoff2]=getTimes(email);

    let allowed=false;
    const date = new Date();
    const [min, hour, month, day, year]       = [date.getMinutes(), date.getHours(), date.getMonth(), date.getDate(), date.getFullYear()];
    
    console.log('hour:', hour, 'checktimes:',  start1, end1, start2, end2);

    if (Number(hour)>=Number(start1) && Number(hour)<Number(end1)){
         allowed=true
    }
    if (Number(hour)>=Number(start2) && Number(hour)<Number(end2)){
         allowed=true
    }
    return allowed
}


const startWorkDB = (setDone,  email) => {
                const date = new Date();   
                const time=Number(date.getTime());
                const [min, hour, month, day, year]= [date.getMinutes(), date.getHours(), date.getMonth(), date.getDate(), date.getFullYear()];
                
                // Check if we are in the allowed working times:

               let allowed=checkTimes(email);

          //     console.log( "startWorkDB allowed: ",  allowed);

               if (allowed){

                    var Data = {
                        laboralYear:Number(year),
                        laboralTime:Number(time),
                        email:email,
                        day:day,
                        month:month,
                        hour:hour,
                        min:min,
                        action:'start'
                    };
                    var params = {
                        TableName:'tiempolaboral',
                        Item: Data
                    }
        
                    docClient.put(params, function (err, data) {
                        if (err) {
                            console.log('Error', err)
                        } else {
                            console.log('Success', data);
                            setDone(true);
                        }
                    })
                }else{

                    setDone(false);
                }
}


const stopWorkDB = (setDone,  email) => {
                const date = new Date();   
                const time=Number(date.getTime());
                const [min, hour, month, day, year]       = [date.getMinutes(), date.getHours(), date.getMonth(), date.getDate(), date.getFullYear()];
                var Data = {
                    laboralYear:Number(year),
                    laboralTime:Number(time),
                    email:email,
                    day:day,
                    month:month,
                    hour:hour,
                    min:min,
                    action:'stop'
                };
                var params = {
                    TableName:'tiempolaboral',
                    Item: Data
                }
    
                docClient.put(params, function (err, data) {
                    if (err) {
                        console.log('Error', err)
                    } else {
                        console.log('Success', data);
                        setDone();
                    }
                })
}

const getStatusDB = (setDone, year, email) => {
    var params = {
        TableName: 'tiempolaboral',
        KeyConditionExpression: 'laboralYear = :y',
        ExpressionAttributeValues: {
            ':y':year
        }
    };
    let lastaction='';
    let lasttime=0;

    docClient.query(params, function (err, data) {
        if (!err) {
           // console.log(data);
            data.Items.forEach(function(employee) {
                console.log("employee:",employee);
                if (employee.email==email) {
                    if (lasttime<Number(employee.laboralTime)){
                        lasttime=Number(employee.laboralTime);
                        lastaction=employee.action;
                    }
                }
            })

            console.log("getStatusDB: ", email, lastaction, lasttime);
            setDone(lastaction, lasttime);
        }
        else{
            console.log('Error',err)
        }
    })
 };

// FAKE clicking just show the status of the employee based on their time in employees.json
// Reports will be created with createReport.js 

const getStatusFAKE = (setDone, year, email) => {
   
    let lastaction='';
    let lasttime=0;
    let start1=null, end1=null, start2=null, end2=null, dayoff1=null, dayoff2=null;

    const date = new Date();   
    const time=Number(date.getTime());
    const [min, hour, month, day]       = [date.getMinutes(), date.getHours(), date.getMonth(), date.getDate()];

    [start1,end1,start2,end2, dayoff1, dayoff2]=getTimes(email);
    console.log("getStatusFAKE: ", email);

    let started=false;

    if ( hour>=start1 && hour<end1) {
        const minStart=Math.floor(Math.random() * 10);
        const hourStart= start1
        const dateStart = new Date(year,month,day,hourStart,minStart)
        //const dateStart =new Date(dateStart0)
        console.log("start:", dateStart.toTimeString({timeZone: "Europe/Madrid"}));
        const timeStart = Number(dateStart.getTime());
        started=true
        lasttime=timeStart
        console.log("getStatusFAKE: time started:",lasttime);
    }

    if ( hour>=start2 && hour<end2) {
        const minStart=Math.floor(Math.random() * 10);
        const hourStart= start2
        const dateStart = new Date(year,month,day,hourStart,minStart)
        console.log("start:", dateStart.toTimeString({timeZone: "Europe/Madrid"}));
        const timeStart = Number(dateStart.getTime());
        started=true
        lasttime=timeStart
        console.log("getStatusFAKE: time started:", lasttime);
    }

    if (started){

        lastaction='start'

    }else{

        lastaction='end'
    }
    
    setDone(lastaction, lasttime);
 };

// Not used this function
export const getStatus = async  (setDone, email) => {
    var date=new Date();  
    const year=date.getFullYear();
    // NOTE: FAKE is to simulate the clicking
    // await getStatusDB(setDone, year, email );
    await getStatusFAKE(setDone, year, email );
}

// Not used this function
export const startWork = async  (setDone, email) => {
    await startWorkDB(setDone,  email );
}

export const stopWork = async  (setDone, email) => {
    await stopWorkDB(setDone,  email );
}

// **********************************************

  