
//LOGIN
import { sha256 } from 'crypto-hash';
import { useState } from "react";
import employees from '../employees.json';

//console.log(employees[0].name);

export const checkCredentials= async (email,password, setDone) => {
        let check_password, check_email, result;

        let credentials=email+password;
        if (email==null || password==null){
            result=false;
            setDone(false);
            
        }else{
                await sha256(credentials).then(hash => {
                 //   console.log("Obtained hash credentials:", hash, process.env.REACT_APP_CREDENTIALS_ADMIN);
                      let found=false;
                      let name='';
                      employees.forEach(function(employee) {

                        if (hash==employee.password){
                             found=true;
                             name=employee.name;
                        }

                     })//forEach

                    if (found){
                         sessionStorage.setItem("isAuthenticated", true);
                         sessionStorage.setItem("emailEmployee",   email);
                         sessionStorage.setItem("nameEmployee",    name);
                         setDone(true,email,name);
                    }else{
                         sessionStorage.setItem("isAuthenticated", false);
                         sessionStorage.setItem("emailEmployee",   '');
                         sessionStorage.setItem("nameEmployee",    '');
                         setDone(false,email,name);
                    }
                });
        }
}

export const setAuth= (value) => {
        sessionStorage.setItem("isAuthenticated", value);
}

export const getAuth= () => {
        let result;
        if (sessionStorage.getItem("isAuthenticated")=='true'){
            result=true;
        }else{
            result=false;
        }
        return result;
}


export const getEmail= () => {
        let result=sessionStorage.getItem("emailEmployee");
        return result;
}

export const getName= () => {
        let result=sessionStorage.getItem("nameEmployee");
        return result;
}

export const getAuthError= () => {
        let result;
        if (sessionStorage.getItem("AuthenticatedShowError")=='true'){
            result=true;
        }else{
            result=false;
        }
        return result;
}

export const getAuthLogin= () => {
        let result;
        if (sessionStorage.getItem("AuthenticatedShowLogin")=='true'){
            result=true;
        }else{
            result=false;
        }
        return result;
}

export const  handleAuthentication = (action) => {
                    //  console.log(" handleAuthentication: ", action);
                      if (action=='login'){
                               sessionStorage.setItem("AuthenticatedShowLogin", true); 
                               sessionStorage.setItem("isAuthenticated", false);
                               window.location.href = process.env.REACT_APP_PUBLIC_URL+'click/hotelsancho';
                      }
                      if (action=='logout'){
                               sessionStorage.setItem("AuthenticatedShowError", false);
                               sessionStorage.setItem("AuthenticatedShowLogin", false);
                               sessionStorage.setItem("isAuthenticated", false);
                               window.location.href = process.env.REACT_APP_PUBLIC_URL+'click/hotelsancho';
                      }
                      if (action=='valid'){
                          sessionStorage.setItem("isAuthenticated", true);
                          sessionStorage.setItem("AuthenticatedShowError", false);
                          sessionStorage.setItem("AuthenticatedShowLogin", false);
                          window.location.href = process.env.REACT_APP_PUBLIC_URL+'click/hotelsancho';
                      }
                      if (action=='error'){
                          sessionStorage.setItem("isAuthenticated", false);
                          sessionStorage.setItem("AuthenticatedShowError", true);
                          sessionStorage.setItem("AuthenticatedShowLogin", true);
                          window.location.href = process.env.REACT_APP_PUBLIC_URL+'click/hotelsancho';
                      }
};

